/* eslint-disable no-console */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

// ant desing

// components
import Sidebar from '../Sidebar/sidebar';
import Modal, { nameFiles, tableRef } from '../Modal/modal';

import { layoutContext } from 'context';
// css
import './style.css';
import { getStatusCount, getStatusReport, getExistance } from '../../services';
import { Loading } from '../../common';
import { Result } from 'antd';
//utils
import { messageError } from 'utils/messages';
import { ContextDashboards } from '../ContextDashboards';
export const borderClasses = (amount) => {
  if (amount < 80) {
    return 'red';
  } else if (amount >= 80 && amount < 95) {
    return 'yellow';
  } else if (amount >= 95 && amount <= 100) {
    return 'green';
  } else {
    return 'blue';
  }
};

const colors = {
  'En proceso': '#00499A',
  'En validación': '#B55CFF',
  'En segunda validación': '#FFB54C',
  Aprobado: '#00C14F',
  Rechazado: '#E6001D',
  'Rechazado parcialmente': '#F16800',
  Total: '',
};

function SalesChallengeDashboard() {
  const [loading, setLoading] = useState(true);
  const [statusCount, setStatusCount] = useState([]);
  const [statusReport, setStatusReport] = useState([]);
  const [year, setYear] = useState(0);
  const [filters, setFilters] = useState({});
  const [queryS, setQueryS] = useState('');
  const [querySOriginal, setQuerySOriginal] = useState('');
  const [columns, setColumns] = useState([]);

  const history = useHistory();

  const getInfo = async (query) => {
    setStatusCount(await getStatusCount(query));
    setStatusReport([]); // bug añade columnas
    const report = await getStatusReport(query);
    setStatusReport(report.rows);
    setYear(report.processYear);
  };

  const loadingCall = (query) => {
    setLoading(true);
    getInfo(query)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const { onChangeActiveComponent } = useContext(layoutContext);

  useEffect(() => {
    onChangeActiveComponent(ContextDashboards);
    checkForSalesGoalsChallenge();
  }, []);

  const checkForSalesGoalsChallenge = async () => {
    //check for existance of Sales, SalesGoal and Challenge
    let existance = {};
    let values;
    existance.sales = await getExistance('SalesExists');
    existance.goal = await getExistance('SalesGoalExists');
    existance.challenge = await getExistance('ChallengeExists');
    values = Object.values(existance);
    values.forEach((item, index) => {
      if (item === false) {
        switch (index) {
          case 0: //SalesExists
            messageError(
              'Es necesario registrar la información de ventas históricas antes de realizar la consulta del dashboard.'
            );
            break;
          case 1: //SalesGoalExists
            messageError(
              'Es necesario registrar la información de metas de venta del año de vigencia del proceso antes de realizar la consulta del dashboard.'
            );
            break;
          case 2: //ChallengeExists
            messageError(
              'Es necesario registrar la información de desafío de ventas del año de vigencia del proceso antes de realizar la consulta del dashboard.'
            );
            break;
          default:
            break;
        }
      }
    });
    if (values.includes(false)) {
      history.push('/dashboards');
    }
  };
  function filtersChanged(allValues) {
    loadingCall(allValues.queryString);
    setFilters(allValues?.currentFilters ? allValues.currentFilters : {});
    setQuerySOriginal(allValues.queryString);
    allValues.queryString = allValues.queryString.replace('?challengeData=1', '');
    allValues.queryString = allValues.queryString.replace('?challengeData=2', '');
    allValues.queryString = allValues.queryString.replace('challengeData=1', '');
    allValues.queryString = allValues.queryString.replace('challengeData=2', '');
    setQueryS(allValues.queryString);
    let termination =
      allValues.challengeData === '2' || allValues.challengeData === 2 ? 'Volume' : 'Amount';
    setColumns([]);
    setColumns([
      {
        title: 'Estructura Comercial',
        dataIndex: 'structure',
        key: 'structure',
      },
      {
        title: 'Desafío ',
        dataIndex: 'challenge' + termination,
        key: 'challenge' + termination,
      },
      {
        title: 'Meta de venta ',
        dataIndex: 'salesGoal' + termination,
        key: 'salesGoal' + termination,
      },
      {
        title: 'Desafío vs Meta de venta',
        dataIndex: `challengeSalesGoal${termination}`,
        key: `challengeSalesGoal${termination}`,
      },
      {
        title: '%',
        dataIndex: `percChallengeSalesGoal${termination}`,
        key: `percChallengeSalesGoal${termination}`,
      },
      {
        title: 'Ventas Históricas ',
        dataIndex: 'sales' + termination,
        key: 'sales' + termination,
      },
      {
        title: 'Desafío vs Ventas Históricas',
        dataIndex: 'challengeSales' + termination,
        key: 'challengeSales' + termination,
      },
      {
        title: '%',
        dataIndex: 'percChallengeSales' + termination,
        key: 'percChallengeSales' + termination,
      },
    ]);
  }

  const renderTitle = (title) => {
    if (title === 'Desafío ' || title === 'Meta de venta ' || title === 'Ventas Históricas ') {
      if (title.includes('Históricas')) {
        return title + (year?.toString() ? (year - 1)?.toString() : '');
      }
      return title + (year?.toString() ? year?.toString() : '');
    } else {
      return title;
    }
  };

  return (
    <div className={'sales-challenge-mc'}>
      <Loading loading={loading} />
      <div>
        <div className={'width2'}>
          <table className={'salesC-sales-table'} id={tableRef.salesChallenge}>
            <tbody>
              <tr>
                <th className="th"></th>
                {columns.map((item) => {
                  return <th key={item.key}>{renderTitle(item.title)}</th>;
                })}
              </tr>
              {(statusReport?.length < 2 || queryS?.length === 0) && (
                <tr>
                  <td colSpan={'100%'}>
                    <Result
                      className="result-svg"
                      status="500"
                      title={
                        'No se encontraron resultados ' +
                        'que coincidan con los ' +
                        'criterios de búsqueda ingresados'
                      }
                    />
                  </td>
                </tr>
              )}
              {statusReport?.length >= 2 &&
                statusReport.map((item) => {
                  return (
                    <tr key={item.structure + 'c'} className={item.structure.toLowerCase()}>
                      <td
                        className="body"
                        style={{
                          backgroundColor:
                            item.structure === 'Total' ? '#0078B3' : colors[item.status],
                        }}
                      />
                      {columns &&
                        columns.map((col, index) => {
                          return (
                            <td
                              key={index + 'd'}
                              className={
                                col.dataIndex.includes('percChallengeSales') ||
                                col.dataIndex.includes('percChallengeSalesGoal')
                                  ? borderClasses(item[col.dataIndex])
                                  : ''
                              }
                            >
                              {item[col.dataIndex]}
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <br />
          <div className={'square'}>
            <div className={'red'} />
            {'< 80%'}
            <div className={'yellow'} />
            {'80% a < 95%'}
            <div className={'green'} />
            {'95% a 100%'}
            <div className={'blue'} />
            {'> 100%'}
          </div>
          <br />
          <Modal
            tableId={tableRef.salesChallenge}
            filters={filters}
            query={querySOriginal}
            nameFile={nameFiles.salesChallenge}
          />
        </div>
        <div className="second-mod">
          <table className="table" id={tableRef.salesStatus}>
            <tbody>
              <tr>
                <th className="th"></th>
                <th className="th">Estatus de desafío de ventas</th>
                <th className="th" />
              </tr>
              <tr>
                <th className="th2" />
                <th className="th2">Estatus</th>
                <th className="th2">Total</th>
              </tr>
              {statusCount?.length > 0 &&
                statusReport?.length >= 2 &&
                statusCount.map((item) => {
                  return (
                    <tr key={item.status} className={item.status === 'Total' ? 'status-total' : ''}>
                      <td className="body">
                        <div className={item.status.toLowerCase().replace(/ /g, '')} />
                      </td>
                      <td className="body2">{item.status}</td>
                      <td className="body3">{item.count}</td>
                    </tr>
                  );
                })}
              {(statusReport?.length < 2 || queryS.length === 0) && (
                <tr>
                  <td colSpan={'100%'}>
                    <Result
                      className="result-svg"
                      status="500"
                      title={
                        'No se encontraron resultados ' +
                        'que coincidan con los ' +
                        'criterios de búsqueda ingresados'
                      }
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <br />
          <Modal
            tableId={tableRef.salesStatus}
            query={querySOriginal}
            nameFile={nameFiles.salesChallenge}
          />
        </div>
        <Sidebar onFiltersChanged={filtersChanged} isLoadingTables={loading} />
      </div>
    </div>
  );
}

export default SalesChallengeDashboard;
