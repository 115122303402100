/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// ant desing
import { Row, Col, Drawer, Button, Collapse, Form } from 'antd';
import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import img1 from './icon_grafica1.png';
import img2 from './icon_grafica2.png';
import img3 from './icon_grafica3.png';
// components
import BSelect from 'common/components/BSelect/BSelect';
// css
import './styles.css';
import { changeState, resetData, valuesFilter } from './helpers';
import { PeriodType } from '../../components';
import userIs from '../../../../utils/userIs';
import { allProductNumberMap } from '../../utils';

import {
  filterDataRequest,
  regionRequest,
  typeClientRequest,
  segmentationRequest,
  businessLineRequest,
  subRegionRequest,
  territoryRequest,
  zoneRequest,
  indicatorRequest,
  typeInformationRequest,
  typeUnidadRequest,
  periodoRequest,
  yearRequest,
  monthRequest,
  clientRequest,
  politicsProductRequest,
  disponibilidadProductRequest,
  familyProductRequest,
  productRequest,
  resetFilter,
  comparativeDashboardRequestMainApi,
  comparativeDashboardRequestCatalogsLoad,
} from '../../redux-saga/actions';

function Sidebar(props) {
  const {
    regionRequest: getRegion,
    typeClientRequest: getTypeClient,
    segmentationRequest: getSegmentation,
    businessLineRequest: getBusinessLines,
    territoryRequest: getTerritory,
    zoneRequest: getZones,
    subRegionRequest: getSubRegion,
    typeInformationRequest: getTypeInfo,
    typeUnidadRequest: getTypeUnidad,
    periodoRequest: getPeriodo,
    yearRequest: getYear,
    monthRequest: getMonth,
    clientRequest: getClient,
    filterDataRequest: filterData,
    stateComparative: stateData,
    politicsProductRequest: getPoliticsProduct,
    familyProductRequest: getFamilyProducto,
    disponibilidadProductRequest: getDisponibleProduct,
    productRequest: getProduct,
    resetFilter: resetFilterData,
    comparativeDashboardRequestMainApi: getComparativeData,
    comparativeDashboardRequestCatalogsLoad: requestCatalogs,
  } = props;

  const {
    filter,
    total,
    typeclient,
    segmentation,
    region,
    businessline,
    subregion,
    territory,
    zone,
    indicator,
    typeinfo,
    typeUnidad,
    periodo,
    year,
    month,
    client,
    politicsproduct,
    disponibilidadproduct,
    familyproduct,
    product,
  } = stateData;
  function getClientParams() {
    return {
      Placement: typeclient.map((item) => item.key),
      BusinessLines: businessline.map((item) => item.key),
      Segmentation: segmentation.map((item) => item.key),
      Territories: territory.map((item) => item.key),
      Subregions: subregion.map((item) => item.key),
    };
  }

  const [visible, setVisible] = useState(false);
  const [stateTotal, setTotal] = useState(true);

  let typevalueOptions = [
    { value: 'Unitario', key: 0 },
    { value: 'Acumulativo', key: 1 },
  ];

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const hideDrawer = () => {
    setVisible(false);
  };
  const resetForm = async () => {
    await resetFilterData(resetData(stateData));
    await form.setFieldsValue(valuesFilter(stateData));
    await getClient({ params: getClientParams(), form: form });
    await getProduct({ params: null, form: form });
    await getComparativeData();
  };
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const getCatalogData = () => {
    getRegion();
    getTypeClient();
    getSegmentation();
    getBusinessLines();
    getSubRegion();
    getTerritory();
    getZones();

    getTypeInfo();
    getTypeUnidad();
    getPeriodo();
    getYear();
    getMonth();
    getClient({ params: {}, form: form });
    getPoliticsProduct();
    getDisponibleProduct();
    getFamilyProducto();
    getProduct();
  };

  function getClientHandleChangeParams(value, name, options) {
    const clientParams = {};
    if (filter[17].segmentation.length > 0) {
      clientParams.Segmentation = filter[17].segmentation;
    }

    if (filter[14].typeclient.length > 0) {
      clientParams.Placement = filter[14].typeclient;
    }

    if (filter[12].territory.length > 0) {
      clientParams.Territories = filter[12].territory;
    }

    if (filter[10].bussinesline.length > 0) {
      clientParams.BusinessLines = filter[10].bussinesline;
    }

    if (filter[11].subregions.length > 0) {
      clientParams.Subregions = filter[11].subregions;
    }

    switch (name) {
      case 'bussinesline':
        if (value.length > 0) {
          clientParams.BusinessLines = value;
        } else {
          delete clientParams.BusinessLines;
        }
        break;
      case 'typeclient':
        if (value.length > 0) {
          clientParams.Placement = value;
        } else {
          delete clientParams.Placement;
        }
        break;
      case 'segmentation':
        if (value.length > 0) {
          clientParams.Segmentation = value;
        } else {
          delete clientParams.Segmentation;
        }

        break;
      case 'territory':
        if (value.length > 0) {
          clientParams.Territories = value;
        } else {
          delete clientParams.Territories;
        }
        break;
      case 'subregions':
        if (value.length > 0) {
          clientParams.Subregions = value;
        } else {
          delete clientParams.Subregions;
        }
        break;
      default:
        break;
    }

    return clientParams;
  }

  const handleChange = (value, name, options) => {
    if (value?.length) {
      if (value.includes('ALL')) {
        if (value.length === options.length + 1) {
          form.setFieldsValue({ [name]: [] });
          value = [];
        } else {
          value = options.map((option) => {
            return option.key;
          });
          form.setFieldsValue({
            [name]: value,
          });
        }
      }
    }

    if (
      name === 'bussinesline' ||
      name === 'segmentation' ||
      name === 'typeclient' ||
      name === 'territory' ||
      name === 'subregions'
    ) {
      getClient({ params: getClientHandleChangeParams(value, name), form: form });
    }

    if (
      name === 'territory' ||
      name === 'bussinesline' ||
      name === 'subregions' ||
      name === 'politicscp' ||
      name === 'availavilityproduct'
    ) {
      const receivedParams = allProductNumberMap(filter, name, value);

      getProduct({ params: receivedParams, form: form });
    }
  };

  useEffect(() => {
    getCatalogData();
  }, []);

  useEffect(() => {
    if (total === 17 && stateTotal === true) {
      requestCatalogs({ form });
      setTotal(false);
    }
  }, [total]);

  const getDefaultValues = (array) => {
    let newArray = [];
    if (array !== undefined && array !== null && Array.isArray(array)) {
      array.forEach((item) => {
        newArray.push(item.key);
      });
    }
    return newArray;
  };

  const resetFormFilter = () => {
    const spreadedFilter = [...filter];
    let singleYear = [];
    spreadedFilter[8].month = [];
    filterData(spreadedFilter);
    form.setFieldsValue({ month: [] });
    if (periodo !== 1) {
      singleYear.push(year[0].key);
      spreadedFilter[7].year = singleYear;
      form.setFieldsValue({ year: singleYear });
    }
    getComparativeData();
  };

  const handlePeriodChange = (data) => {
    resetFormFilter();
    getMonth(data);
    handleChange(data, 'periodo', periodo);
  };

  let indicador2 = [];
  let indicador3 = [];
  let indicador1 = [];
  if (indicator) {
    indicador1 = indicator.filter((value, index, array) => {
      return value.key !== filter[2].indicator2 && value.key !== filter[3].indicator3;
    });
    indicador2 = indicator.filter((value, index, array) => {
      return value.key !== filter[1].indicator1 && value.key !== filter[3].indicator3;
    });
    indicador3 = indicator.filter((value, index, array) => {
      return value.key !== filter[1].indicator1 && value.key !== filter[2].indicator2;
    });
  }

  function handleYearChange(data, id, filterItem) {
    if (data.length === 0) {
      data = year[0].key;
      form.setFieldsValue({ year: data });
    }
    if (data.length > 3) {
      data.pop();
    }

    return handleChange(data, id, filterItem);
  }

  function countIndicators() {
    const indicatorsList = [filter[1].indicator1, filter[2].indicator2, filter[3].indicator3];
    let result = 0;

    indicatorsList.forEach((item) => {
      if (item !== undefined) {
        result++;
      }
    });
    return result;
  }

  function commercialAggrementSelected() {
    const indicatorsList = [filter[1].indicator1, filter[2].indicator2, filter[3].indicator3];

    return indicatorsList.includes('agreement') || indicatorsList.includes('agreementVolume');
  }

  function singleOrMultipleYears() {
    if (countIndicators() === 1 || filter[6].periodo === 1) {
      return 'multiple';
    }
  }

  function shouldCallApiOnChange(name) {
    let shouldCall = true;
    switch (name) {
      case 'bussinesline':
      case 'politicscp':
      case 'availavilityproduct':
      case 'territory':
      case 'typeclient':
      case 'segmentation':
      case 'subregions':
        shouldCall = false;
        break;
      default:
        break;
    }

    return shouldCall;
  }

  return (
    <>
      <div className={'sidebar-mc-btn'}>
        <Button type="primary" onClick={showDrawer}>
          <FilterOutlined />
        </Button>
      </div>
      <div>
        <Drawer closable={false} mask={false} onClose={onClose} visible={visible}>
          <Row className="drawer-header">
            <Col span={1}>
              <FilterOutlined className="hand-cursor" onClick={hideDrawer} />
            </Col>
            <Col span={6} offset={4}>
              <p>Filtros</p>
            </Col>
            <Col span={1} offset={12}>
              <ReloadOutlined className="hand-cursor" onClick={resetForm} />
            </Col>
          </Row>
          <Form
            form={form}
            //onFinish={onfinish}
            onValuesChange={(changedValues, allValues) => {
              let dataNew = changeState(form, stateData, changedValues, filterData);
              const keyArray = Object.keys(changedValues);
              const changedValueName = keyArray[0];
              filterData(dataNew);
              if (shouldCallApiOnChange(changedValueName)) {
                getComparativeData();
              }
            }}
          >
            <Collapse accordion defaultActiveKey={['1']}>
              <Panel header="Gráfico" key="1" showArrow={false}>
                {/* <p>Tipo de vista</p>
                <BSelect
                  allowClear={false}
                  defaultValue={1}
                  name="typeview"
                  options={[
                    { value: 'grafico', key: 1 },
                    { value: 'tabla', key: 2 },
                  ]}
                  label={''}
                />  */}

                <p style={{ display: 'flex' }}>
                  Indicador a comparar{' '}
                  <img
                    style={{ marginLeft: '10px' }}
                    width="50"
                    height="20"
                    src={img1}
                    alt="Images Upload"
                  />
                </p>

                <BSelect
                  //mode={'multiple'}
                  name="indicator1"
                  defaultValue={'salesChallenge'}
                  options={indicador1}
                  label={''}
                  //loading={businessLinesLoaded}
                  //selectAll={true}
                  onChange={(data, options) => handleChange(data, 'indicator1', indicador1)}
                  maxTagCount={3}
                  allowClear={false}
                />

                <p style={{ display: 'flex' }}>
                  Indicador a comparar{' '}
                  <img
                    style={{ marginLeft: '10px' }}
                    width="50"
                    height="20"
                    src={img2}
                    alt="Images Upload"
                  />
                </p>
                <BSelect
                  //mode={'multiple'}
                  name="indicator2"
                  options={indicador2}
                  defaultValue={'agreement'}
                  label={''}
                  //loading={subregionsLoaded}
                  //selectAll={true}
                  onChange={(data) => handleChange(data, 'indicator2', indicador2)}
                  maxTagCount={3}
                />

                <p style={{ display: 'flex' }}>
                  Indicador a comparar{' '}
                  <img
                    style={{ marginLeft: '10px' }}
                    width="50"
                    height="20"
                    src={img3}
                    alt="Images Upload"
                  />
                </p>
                <BSelect
                  //mode={'multiple'}
                  name="indicator3"
                  options={indicador3}
                  label={''}
                  //loading={areasLoaded}
                  //selectAll={true}
                  onChange={(data) => handleChange(data, 'indicator3', indicador3)}
                  maxTagCount={3}
                />

                <p>Tipo de información</p>
                <BSelect
                  // mode={'multiple'}
                  allowClear={false}
                  name="typeinformation"
                  options={typeinfo}
                  defaultValue={1}
                  label={''}
                  // loading={zonesLoaded}
                  //selectAll={true}
                  onChange={(data) => handleChange(data, 'typeinformation', typeinfo)}
                  maxTagCount={3}
                />

                <p>Tipo de unidad</p>
                <BSelect
                  // mode={'multiple'}
                  name="typeunidad"
                  options={typeUnidad}
                  defaultValue={'Unidad'}
                  label={''}
                  //loading={regionsLoaded}
                  //selectAll={true}
                  onChange={(data) => handleChange(data, 'typeunidad', typeUnidad)}
                  allowClear={false}
                />
                <p>Tipo de valor</p>
                <BSelect
                  // mode={'multiple'}
                  name="typevalue"
                  options={typevalueOptions}
                  defaultValue={'Unitario'}
                  label={''}
                  //loading={regionsLoaded}
                  //selectAll={true}
                  onChange={(data) => handleChange(data, 'typevalue', typevalueOptions)}
                  maxTagCount={3}
                  allowClear={false}
                />
              </Panel>

              <Panel header="Periodo" key="2" showArrow={false}>
                <p>Periodo</p>
                <BSelect
                  //mode={'multiple'}
                  defaultValue={periodo && periodo[0]?.key}
                  name="periodo"
                  options={periodo}
                  label={''}
                  onChange={handlePeriodChange}
                  maxTagCount={3}
                  allowClear={false}
                  showSearch={false}
                />

                <>
                  <p>Año{!singleOrMultipleYears() ? '' : 's'}</p>
                  <BSelect
                    allowClear={false}
                    mode={singleOrMultipleYears()}
                    name="year"
                    defaultValue={year && year[0]?.key}
                    options={year}
                    label={''}
                    //loading={businessLinesLoaded}
                    //selectAll={true}
                    onChange={(data) => handleYearChange(data, 'year', year)}
                    maxTagCount={3}
                  />
                </>

                <PeriodType
                  periodType={filter[6].periodo}
                  month={month}
                  handleChange={handleChange}
                />
              </Panel>

              <Panel header="Estructura comercial" key="3" showArrow={false}>
                <p>Región</p>
                <BSelect
                  mode={'multiple'}
                  name="regions"
                  options={region}
                  defaultValue={getDefaultValues(region)}
                  label={''}
                  //loading={regionsLoaded}
                  selectAll={true}
                  onChange={(data) => handleChange(data, 'regions', region)}
                  maxTagCount={3}
                />

                <p>Línea de negocio</p>
                <BSelect
                  showSearch={false}
                  mode={'multiple'}
                  name="bussinesline"
                  options={businessline}
                  defaultValue={getDefaultValues(businessline)}
                  label={''}
                  //loading={businessLinesLoaded}
                  selectAll={true}
                  onChange={(data) => handleChange(data, 'bussinesline', businessline)}
                  maxTagCount={3}
                />

                <p>Subregión</p>
                <BSelect
                  mode={'multiple'}
                  name="subregions"
                  options={subregion}
                  label={''}
                  defaultValue={
                    userIs('Gerente de ventas', 'Representante de ventas')
                      ? getDefaultValues(subregion)
                      : []
                  }
                  //loading={subregionsLoaded}
                  selectAll={true}
                  onChange={(data) => handleChange(data, 'subregions', subregion)}
                  maxTagCount={3}
                />

                <p>Territorio</p>
                <BSelect
                  showSearch={false}
                  mode={'multiple'}
                  name="territory"
                  options={territory}
                  defaultValue={
                    userIs('Representante de ventas') ? getDefaultValues(territory) : []
                  }
                  label={''}
                  //loading={areasLoaded}
                  selectAll={true}
                  onChange={(data) => handleChange(data, 'territory', territory)}
                  maxTagCount={3}
                />

                <p>Zona</p>
                <BSelect
                  mode={'multiple'}
                  name="zones"
                  options={zone}
                  label={''}
                  //loading={zonesLoaded}
                  selectAll={true}
                  onChange={(data) => handleChange(data, 'zones', zone)}
                  maxTagCount={3}
                  disabled={commercialAggrementSelected()}
                />
                <p
                  id={'zoneMessage'}
                  style={{
                    display: commercialAggrementSelected() ? 'block' : 'none',
                  }}
                >
                  El valor del filtro zona no aplica porque se seleccionó el indicador &quot;Acuerdo
                  comercial&quot;
                </p>
              </Panel>
              <Panel header="Clientes" key="4" showArrow={false}>
                <p>Tipo de cliente</p>
                <BSelect
                  mode={'multiple'}
                  name="typeclient"
                  options={typeclient}
                  defaultValue={getDefaultValues(typeclient)}
                  label={''}
                  //loading={salesStatusLoaded}
                  selectAll={true}
                  onChange={(data) => handleChange(data, 'typeclient', typeclient)}
                  maxTagCount={3}
                />
                <p>Cliente</p>
                <BSelect
                  mode={'multiple'}
                  name="client"
                  options={client}
                  defaultValue={getDefaultValues(client)}
                  label={''}
                  selectAll={true}
                  onChange={(data) => handleChange(data, 'client', client)}
                  maxTagCount={3}
                />
                <p>Segmentación</p>
                <BSelect
                  mode={'multiple'}
                  name="segmentation"
                  options={segmentation}
                  //defaultValue={getDefaultValues(segmentation)}
                  label={''}
                  selectAll={true}
                  onChange={(data) => handleChange(data, 'segmentation', segmentation)}
                  maxTagCount={3}
                />
              </Panel>
              <Panel header="Productos" key="5" showArrow={false}>
                <p>Politica comercial-Productos</p>
                <BSelect
                  mode={'multiple'}
                  name="politicscp"
                  options={politicsproduct}
                  defaultValue={getDefaultValues(politicsproduct)}
                  label={''}
                  //loading={salesStatusLoaded}
                  selectAll={true}
                  onChange={(data) => handleChange(data, 'politicscp', politicsproduct)}
                  maxTagCount={3}
                />
                <p>Disponibilidad-Productos</p>
                <BSelect
                  mode={'multiple'}
                  name="availavilityproduct"
                  options={disponibilidadproduct}
                  defaultValue={getDefaultValues(disponibilidadproduct)}
                  label={''}
                  //loading={salesStatusLoaded}
                  selectAll={true}
                  onChange={(data) =>
                    handleChange(data, 'availavilityproduct', disponibilidadproduct)
                  }
                  maxTagCount={3}
                />
                <p>Familia</p>
                <BSelect
                  mode={'multiple'}
                  name="family"
                  options={familyproduct}
                  defaultValue={getDefaultValues(familyproduct)}
                  label={''}
                  selectAll={true}
                  onChange={(data) => handleChange(data, 'family', familyproduct)}
                  maxTagCount={3}
                />

                <p>Productos</p>
                <BSelect
                  mode={'multiple'}
                  name="product"
                  options={product}
                  defaultValue={getDefaultValues(product)}
                  label={''}
                  selectAll={true}
                  onChange={(data) => handleChange(data, 'product', product)}
                  maxTagCount={3}
                />
              </Panel>
            </Collapse>
          </Form>
        </Drawer>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    stateComparative: state.COMPARATIVE,
  };
};

export default connect(mapStateToProps, {
  filterDataRequest,
  regionRequest,
  typeClientRequest,
  segmentationRequest,
  businessLineRequest,
  subRegionRequest,
  territoryRequest,
  zoneRequest,
  indicatorRequest,
  typeInformationRequest,
  typeUnidadRequest,
  periodoRequest,
  yearRequest,
  monthRequest,
  clientRequest,
  politicsProductRequest,
  disponibilidadProductRequest,
  familyProductRequest,
  productRequest,
  resetFilter,
  comparativeDashboardRequestMainApi,
  comparativeDashboardRequestCatalogsLoad,
})(Sidebar);
