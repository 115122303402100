/* eslint-disable no-shadow */
import React, { useContext, useEffect, useState } from 'react';
import { Row, Table, Upload, Modal } from 'antd';
import { layoutContext } from '../context';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { Loader } from '../global';
import { openNotification, ActionButtons } from '../common';
import { uploadCatalog64 } from './services';

/* Styles for buttons */
export const actionButtonsStyle = {
  margin: '16px',
  marginTop: '30px',
  color: 'white',
  borderRadius: '5px',
  height: '35px',
  width: '220px',
  fontSize: '1.1em',
  border: 'none',
  fontWeight: '600',
};

const cancelButton = {
  ...actionButtonsStyle,
  backgroundColor: '#247ba9',
};

const submitButton = {
  ...actionButtonsStyle,
  backgroundColor: '#004a96',
};

export const validateTypeAndSize = (file) => {
  let xls = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  let size = file.size / 1024 / 1024 < 20;
  return xls && size;
};

export const handleSummaryOk = (catalogName, duplicated, templateLiterals, onOk, onCancel) => {
  /* Show duplicated modal only if theres any */
  if (duplicated.length > 0) {
    let message = `Existen ${duplicated.length} registros repetidos de ${catalogName}, ¿desea remplazarlos o ignorarlos?`;
    Modal.confirm({
      title: templateLiterals.title,
      content: <p>{message}</p>,
      okText: 'Remplazar todos',
      cancelText: 'Mantener todos',
      okButtonProps: { size: 'middle', type: 'primary', style: { ...submitButton, width: 'auto' } },
      onOk() {
        onOk(true);
      },
      cancelButtonProps: {
        size: 'middle',
        type: 'default',
        style: { ...cancelButton, width: 'auto' },
      },
      onCancel() {
        onCancel(false);
      },
      width: 500,
    });
  } else {
    onOk(true);
  }
};

function Upload64() {
  /* Upload Flow
        User Add local file
        Before upload
            Validate file
            Send file to service
            Set loading true
        After service response 
            Handle success 
            Handle preview table 
            Handle modal 1
            If there are duplication or errores
                Handle modal 2
                Handle gloabal response (overwrite)
        User send files 
            Set global response (save: true)
            send file to service 
            Handle success or errors
    */

  /* Handle Global application state and context */
  let location = useLocation();
  const history = useHistory();
  const { t } = useTranslation(['catalogs']);
  const { onChangeActiveComponent } = useContext(layoutContext);
  const originalRouter = location.pathname;
  let catalogName = originalRouter.includes('users') ? 'users' : originalRouter.split('/')[2];
  const catalogType = location.pathname.includes('historic') ? 'historic-information' : 'catalogs';

  let catalogTitle = '';
  /* Error message */
  switch (catalogName) {
    case 'exchange-rate':
      catalogTitle = 'tipo de cambio ';
      break;
    case 'process-dates':
      catalogTitle = 'fechas de proceso';
      break;
    case 'clients':
      catalogTitle = 'clientes';
      break;
    case 'selling-goals':
      catalogTitle = 'metas de venta';
      break;
    case 'products':
      catalogTitle = 'productos';
      break;
    case 'sales':
      catalogTitle = 'ventas';
      break;
    default:
      catalogTitle = catalogName;
      break;
  }

  /* Estate for modals */
  const templateLiterals = {
    title: t(`${catalogName}.duplicated.title`),
    param: t(`${catalogName}.duplicated.param`),
    paramName: t(`${catalogName}.duplicated.paramName`),
    paramName2: t(`${catalogName}.duplicated.paramName2`),
  };

  /* File and module states */
  const [file, setFile] = useState(undefined);
  const [uploadState, setUploadState] = useState(undefined);
  const [payloadRows, setPayloadRows] = useState([]);
  const [payloadCorrect, setCorrect] = useState([]);
  const [columns, setColumns] = useState(undefined);
  const [duplicated, setDuplicated] = useState(undefined);

  /* Set preview Columns */
  useEffect(() => {
    import(`./schemas/${catalogName}`).then((schema) => setColumns(schema.uploadColumns));
  }, [catalogName]);

  /* Upload Component props */
  const props = {
    /* Custom request (Call to service) */
    // eslint-disable-next-line no-shadow
    customRequest: ({ onSuccess, onError, file }) => {
      if (validateTypeAndSize(file)) {
        uploadCatalog64(file, catalogName, false, false)
          .then((res) => {
            onSuccess(null, file);
            if (res.objectList === null) {
              setPayloadRows([]);
            } else {
              setPayloadRows(res.objectList);
              setCorrect(res.correct);
            }
            /* Main summary modal */
            // summaryModal(res.total, res.totalErrors, () => {});
            setDuplicated(res.duplicated);
            setUploadState('Result Data');

            if (res.total === res.totalErrors) {
              setFile(undefined);
            } else {
              setFile(file);
            }
          })
          .catch((err) => {
            onError(null);
            setUploadState(undefined);
            setFile(undefined);
          });
      } else {
        openNotification(
          'error',
          `El formato del archivo de carga masiva de ${catalogTitle} debe ser .xlsx y tener un tamaño máximo de 20 MB.`
        );
        setFile(undefined);
        setUploadState(undefined);
      }
    },
    /* File validations, file format XLS and size minor to 20mb */
    // eslint-disable-next-line no-shadow
    beforeUpload: (file) => {
      if (!validateTypeAndSize(file)) {
        setUploadState(undefined);
        return null;
      } else {
        setUploadState('Loading');
        onChangeActiveComponent('catalog');
        return file;
      }
    },
    /* Handle on change */
    // eslint-disable-next-line no-shadow
    onChange({ file }) {
      if (file.status !== 'uploading') {
        setFile(file);
      }
    },
    /* Prevent Antd File List Render */
    showUploadList: false,
  };

  /* Accept button */
  const handleSubmit = () => {
    let clicked = false;
    if (duplicated.length > 0) {
      handleSummaryOk(
        t(`catalogs.${catalogName}`),
        duplicated,
        templateLiterals,
        /* On Ok */
        () => {
          setUploadState('Loading');
          clicked = true;
          uploadCatalog64(file, catalogName, true, true, clicked).then((res) => {
            onChangeActiveComponent(undefined);
            if (catalogType === 'historic-information') {
              history.push(`/historic-information/${catalogName}`);
            } else if (catalogName === 'users') {
              history.push(`/users`);
            } else {
              history.push(`/catalog/${catalogName}`);
            }
          });
        },
        /* On Cancel */
        () => {
          setUploadState('Loading');
          clicked = true;
          uploadCatalog64(file, catalogName, false, true).then((res) => {
            onChangeActiveComponent(undefined);
            if (catalogType === 'historic-information') {
              history.push(`/historic-information/${catalogName}`);
            } else if (catalogName === 'users') {
              history.push(`/users`);
            } else {
              history.push(`/catalog/${catalogName}`);
            }
          });
        }
      );
    } else {
      setUploadState('Loading');
      uploadCatalog64(file, catalogName, false, true).then((res) => {
        onChangeActiveComponent(undefined);
        if (catalogType === 'historic-information') {
          history.push(`/historic-information/${catalogName}`);
        } else if (catalogName === 'users') {
          history.push(`/users`);
        } else {
          history.push(`/catalog/${catalogName}`);
        }
      });
    }
  };
  /* Cancel button */
  const handleCancel = () => {
    setUploadState(undefined);
    setPayloadRows(undefined);
    onChangeActiveComponent(undefined);

    /* Historic cancel button */
    if (catalogName === 'users') {
      history.push(`/`);
    } else {
      switch (catalogType) {
        case 'historic-information':
          history.push(`/historic-information`);
          break;
        case 'catalogs':
        default:
          history.push(`/catalog`);
          break;
      }
    }
  };

  return (
    <>
      <Row justify="end" style={{ width: '100%' }}>
        <Upload {...props} disabled={file && payloadRows.length > 0 ? true : false}>
          <button className="upload-xls-button">Cargar</button>
        </Upload>
      </Row>
      <div className="module-card-transparent" data-testid="upload">
        {!uploadState && <div style={{ height: '40vh' }} />}
        {uploadState === 'Loading' && <Loader />}
        {uploadState === 'Result Data' && (
          <>
            <Table
              scroll={{ x: 'max-content' }}
              columnWidth={'100px'}
              dataSource={payloadRows}
              columns={columns}
            />
          </>
        )}
      </div>
      <ActionButtons
        style={actionButtonsStyle}
        cancelStyle={cancelButton}
        submitStyle={submitButton}
        disabled={payloadRows.length <= 0 || payloadCorrect === 0}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </>
  );
}

export default Upload64;
