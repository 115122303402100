import { throttle, call, put, cancelled, takeLatest } from 'redux-saga/effects';
import {
  CONSULT_RULES_REQUEST,
  consultRulesSuccess,
  consultRulesFail,
  MASSIVE_UPLOAD_RULES_REQUEST,
  massiveUploadRulesSuccess,
  massiveUploadRulesFail,
} from '../actions/rules';
import { ruleTypeEnum } from 'common/models/enums';
import {
  getAgreementsOutOfAvality,
  getAgreementsOutPolicy,
  getAgreementsIncentives,
  massLoadExcelByRuleName,
  getAgreementsOutPolicyClient,
} from '../../services/rules';
import { responseOK } from '../../utils';
import instance from '../../../services/request';
import { summaryEndModal } from 'common/MassiveUploads/utils';
function* workConsultRulesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  const { payload } = action;
  const { endpoint, requestParams } = payload;

  let service;
  switch (endpoint) {
    case ruleTypeEnum.PRODUCTOS_FUERA_DE_DISPONIBILIDAD:
      service = getAgreementsOutOfAvality;
      break;
    case ruleTypeEnum.PRODUCTOS_FUERA_DE_POLITICA:
      service = getAgreementsOutPolicy;
      break;
    case ruleTypeEnum.PROGRAMA_DE_INCENTIVOS:
      service = getAgreementsIncentives;
      break;
    case 'cliente':
      service = getAgreementsOutPolicyClient;
      break;
    default:
      break;
  }
  try {
    const response = yield call(service, { cancelToken: source.token, params: requestParams });
    if (responseOK(response)) {
      yield put(consultRulesSuccess(response.data));
    } else {
      yield put(getAgreementsOutPolicy());
    }
  } catch (error) {
    console.error('Error when trying to execute side effect [redux-sagas-catch]', error);
    yield put(consultRulesFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
      yield put(consultRulesFail());
    }
  }
}

function* workMassLoadRulesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  const { payload } = action;
  const { endpoint, requestParams, onSuccess, history } = payload;
  let requestConfig;
  switch (endpoint) {
    case ruleTypeEnum.PRODUCTOS_FUERA_DE_DISPONIBILIDAD:
      requestConfig = { name: 'OutAvailability' };
      break;
    case ruleTypeEnum.PRODUCTOS_FUERA_DE_POLITICA:
      requestConfig = { name: 'OutPolicy' };
      break;
    case ruleTypeEnum.PROGRAMA_DE_INCENTIVOS:
      requestConfig = { name: 'Incentives' };
      break;
    case 'cliente':
      requestConfig = { name: 'OutPolicyClient' };
      break;
    default:
      break;
  }

  try {
    const response = yield call(massLoadExcelByRuleName, {
      cancelToken: source.token,
      params: {
        ...requestParams,
        content: requestParams?.content.substr(requestParams.content.indexOf(',') + 1),
      },
      ...requestConfig,
    });

    if (responseOK(response)) {
      const objectList = response?.data?.objectList;
      const { data, allCorrectMessage } = response;
      let allCorrect = data.correct > 0;
      data.allCorrectMessage =
        allCorrect === true && allCorrectMessage !== null && allCorrectMessage !== undefined
          ? allCorrectMessage
          : null;
      yield put(massiveUploadRulesSuccess(objectList, data.allCorrectMessage, data.correct));
      if (requestParams?.save)
        yield call(summaryEndModal, response.data, history, '/catalog/businessRules');
      if (onSuccess) onSuccess(response.data);
    } else {
      yield put(massiveUploadRulesFail());
    }
  } catch (error) {
    console.error('Error when trying to execute side effect [redux-sagas-catch]', error);
    yield put(massiveUploadRulesFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
      yield put(massiveUploadRulesFail());
    }
  }
}

export function* watchConsultRulesRequest() {
  yield throttle(3500, CONSULT_RULES_REQUEST, workConsultRulesRequest);
}

export function* watchMassLoadRulesRequest() {
  yield takeLatest(MASSIVE_UPLOAD_RULES_REQUEST, workMassLoadRulesRequest);
}
