/* eslint-disable react/require-default-props */
/* eslint-disable default-case */
/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';

// ant desing
import { Button, Col, Collapse, Drawer, Form, Row } from 'antd';
import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

// components
import BSelect from 'common/components/BSelect/BSelect';
import { salesVolume } from './selectOptions';

// css
import './styles.css';

// service
import {
  getBusinessLines,
  getRegions,
  getSalesStatus,
  getSubregions,
  getTerritories,
  getZones,
} from '../../services/index';

export const FILTERS = {
  regions: 'Regions',
  'bussines-line': 'Lines',
  subregions: 'Subregions',
  zones: 'Zones',
  territories: 'Territories',
  'sales-status': 'Status',
  'sales-volume': 'challengeData',
  all: 'ALL',
  view: 'View',
  clientTypes: 'ClientTypeIds',
  segmentations: 'Segmentation',
  clients: 'Clients',
  productPolicyTypes: 'ProductPolicyTypes',
  agreementStatus: 'Status',
  dataView: 'DataView',
  valueType: 'ValueType',
};

/*{
    "key": 1,
    "value": "Administrador"
},
{
    "key": 2,
    "value": "Administrador de área"
    el administrador de acceso de mercado
    admin de controlling
    admin de supply
    y usuario consultor
},
{
    "key": 4,
    "value": "Gerente de ventas"
},
{
    "key": 5,
    "value": "Representante de ventas"
},
{
    "key": 6,
    "value": "Liderazgo AP"
},
{
    "key": 7,
    "value": "Usuario consultor"
},
{
    "key": 8,
    "value": "KAM"
}*/

export const nameRoles = {
  admin: 1,
  salesManager: 4,
  salesRepresentative: 5,
  kam: 8,
  liderAp: 6,
  area: 2,
  userCons: 7,
};

function Sidebar(props) {
  const { onFiltersChanged, isLoadingTables } = props;
  const [role, setRole] = useState({});
  const [initFlag, setInitFlag] = useState(false);

  // options states
  const [visible, setVisible] = useState(false);
  const [regions, setRegions] = useState([]);
  const [businessLines, setBusinessLines] = useState([]);
  const [subregions, setSubregions] = useState([]);
  const [territories, setTerritories] = useState([]);
  const [zones, setZones] = useState([]);
  const [salesStatus, setSalesStatus] = useState([]);

  // loading states
  const [regionsLoaded, setRegionsLoaded] = useState(true);
  const [businessLinesLoaded, setBusinessLinesLoaded] = useState(true);
  const [subregionsLoaded, setSubregionsLoaded] = useState(true);
  const [territoriesLoaded, setTerritoriesLoaded] = useState(true);
  const [zonesLoaded, setZonesLoaded] = useState(true);
  const [salesStatusLoaded, setSalesStatusLoaded] = useState(true);

  const refStates = {
    Zones: zones,
    Lines: businessLines,
    Regions: regions,
    Subregions: subregions,
    Territories: territories,
    Status: salesStatus,
  };
  // sidebar tabs
  const [activeTab, setActiveTab] = useState('1');

  const adminPreFill = () => {
    return (
      role.roleId === nameRoles.admin ||
      role.roleId === nameRoles.area ||
      role.roleId === nameRoles.kam ||
      role.roleId === nameRoles.userCons ||
      role.roleId === nameRoles.liderAp
    );
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const hideDrawer = () => {
    setVisible(false);
  };

  const resetForm = () => {
    form.resetFields();

    setSidebarData();

    const defaultActiveKey = '1';
    setActiveTab(defaultActiveKey);
  };

  const { Panel } = Collapse;

  const [form] = Form.useForm();

  const getCatalogData = async () => {
    setRegions(await getRegions());
    setRegionsLoaded(false);

    setBusinessLines(await getBusinessLines());
    setBusinessLinesLoaded(false);

    setSubregions(await getSubregions());
    setSubregionsLoaded(false);

    setTerritories(await getTerritories());
    setTerritoriesLoaded(false);

    setZones(await getZones());
    setZonesLoaded(false);

    setSalesStatus(await getSalesStatus());
    setSalesStatusLoaded(false);
  };

  const setSidebarData = async () => {
    if (
      regions.length > 0 &&
      businessLines.length > 0 &&
      subregions.length > 0 &&
      territories.length > 0 &&
      zones.length > 0 &&
      salesStatus.length > 0 &&
      role?.roleId
    ) {
      handleChange(
        adminPreFill() ||
          role.roleId === nameRoles.salesManager ||
          role.roleId === nameRoles.salesRepresentative
          ? [FILTERS.all]
          : [],
        FILTERS.regions,
        renderSelectOptions(regions)
      );
      handleChange(
        adminPreFill() ||
          role.roleId === nameRoles.salesManager ||
          role.roleId === nameRoles.salesRepresentative
          ? [FILTERS.all]
          : [],
        FILTERS['bussines-line'],
        renderSelectOptions(businessLines)
      );
      handleChange([], FILTERS.zones);
      handleChange(
        role.roleId === nameRoles.salesManager || role.roleId === nameRoles.salesRepresentative
          ? [FILTERS.all]
          : [],
        FILTERS.subregions,
        renderSelectOptions(subregions)
      );
      handleChange(
        role.roleId === nameRoles.salesRepresentative ? [FILTERS.all] : [],
        FILTERS.territories,
        renderSelectOptions(territories)
      );
      handleChange([FILTERS.all], FILTERS['sales-status'], salesStatus);
      const allObj = {
        Lines:
          adminPreFill() ||
          role.roleId === nameRoles.salesManager ||
          role.roleId === nameRoles.salesRepresentative
            ? [FILTERS.all]
            : [],
        Regions:
          adminPreFill() ||
          role.roleId === nameRoles.salesManager ||
          role.roleId === nameRoles.salesRepresentative
            ? [FILTERS.all]
            : [],
        Subregions:
          role.roleId === nameRoles.salesManager || role.roleId === nameRoles.salesRepresentative
            ? [FILTERS.all]
            : [],
        Territories: role.roleId === nameRoles.salesRepresentative ? [FILTERS.all] : [],
        Zones: [],
        Status: [FILTERS.all],
        challengeData: [salesVolume[0].key],
      };
      if (allObj.Regions.includes(FILTERS.all)) {
        await regions.forEach((region) => {
          allObj.Regions.push(region.key);
        });
      }
      if (allObj.Lines.includes(FILTERS.all)) {
        await businessLines.forEach((line) => {
          allObj.Lines.push(line.key);
        });
      }
      if (allObj.Subregions.includes(FILTERS.all)) {
        await subregions.forEach((subRegion) => {
          allObj.Subregions.push(subRegion.key);
        });
      }
      if (allObj.Territories.includes(FILTERS.all)) {
        await territories.forEach((territory) => {
          allObj.Territories.push(territory.key);
        });
      }
      if (allObj.Zones.includes(FILTERS.all)) {
        await zones.forEach((zone) => {
          allObj.Zones.push(zone.key);
        });
      }
      if (allObj.Status.includes(FILTERS.all)) {
        await salesStatus.forEach((sales) => {
          allObj.Status.push(sales.key);
        });
      }
      handleFiltersChange(allObj);
      setInitFlag(true);
    }
  };

  useEffect(() => {
    if (initFlag === true) {
      return;
    }

    setSidebarData();
  }, [regions, businessLines, subregions, territories, zones, salesStatus, role]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    setRole(user.role);
    getCatalogData().then();
  }, []);

  const renderSelectOptions = (arr) => {
    return arr.map((current) => {
      return {
        value: current.value,
        key: current.key,
      };
    });
  };

  const handleChange = (value, filter, options) => {
    if (!options) {
      return;
    }
    if (!Array.isArray(value)) {
      return;
    }

    if (value.length && value.includes('ALL')) {
      if (value.length === options.length + 1) {
        form.setFieldsValue({ [filter]: [] });
      } else {
        form.setFieldsValue({
          [filter]: options.map((option) => {
            return option.key;
          }),
        });
      }
    }
  };

  const handleFiltersChange = (allValues, changed) => {
    if (changed) {
      const key = Object.keys(changed)[0];

      if (key && allValues[key] && Array.isArray(allValues[key])) {
        let newDataFilter;
        if (allValues[key][0] === 'ALL') {
          newDataFilter = { [key]: refStates[key].map((value) => value.key) };
        } else if (
          allValues[key].includes('ALL') &&
          allValues[key].length === refStates[key].length + 1
        ) {
          newDataFilter = { [key]: [] };
        } else if (
          allValues[key].includes('ALL') &&
          allValues[key].length < refStates[key].length
        ) {
          newDataFilter = { [key]: refStates[key].map((value) => value.key) };
        } else {
          newDataFilter = { [key]: allValues[key].filter((value) => value !== 'ALL') };
        }

        allValues[key] = newDataFilter[key];
      }
    }

    let currentFilters = '';

    for (const prop in allValues) {
      if (allValues[prop]) {
        const myValues = Array.from(allValues[prop]);
        if (!myValues.includes('ALL')) {
          myValues.forEach((values) => {
            let valueToAdd;
            !currentFilters.length
              ? (valueToAdd = `?${prop}=${values}`)
              : (valueToAdd = `&${prop}=${values}`);
            currentFilters = currentFilters + valueToAdd;
          });
        }
        if (myValues.includes(FILTERS.all)) {
          switch (prop) {
            case FILTERS.regions:
              regions.forEach((values) => {
                let valueToAdd;
                !currentFilters.length
                  ? (valueToAdd = `?${prop}=${values.key}`)
                  : (valueToAdd = `&${prop}=${values.key}`);
                currentFilters = currentFilters + valueToAdd;
              });
              break;
            case FILTERS['bussines-line']:
              businessLines.forEach((values) => {
                let valueToAdd;
                !currentFilters.length
                  ? (valueToAdd = `?${prop}=${values.key}`)
                  : (valueToAdd = `&${prop}=${values.key}`);
                currentFilters = currentFilters + valueToAdd;
              });
              break;
            case FILTERS['sales-status']:
              salesStatus.forEach((values) => {
                let valueToAdd;
                !currentFilters.length
                  ? (valueToAdd = `?${prop}=${values.key}`)
                  : (valueToAdd = `&${prop}=${values.key}`);
                currentFilters = currentFilters + valueToAdd;
              });
              break;
            case FILTERS.subregions:
              subregions.forEach((values) => {
                let valueToAdd;
                !currentFilters.length
                  ? (valueToAdd = `?${prop}=${values.key}`)
                  : (valueToAdd = `&${prop}=${values.key}`);
                currentFilters = currentFilters + valueToAdd;
              });
              break;
            case FILTERS.zones:
              zones.forEach((values) => {
                let valueToAdd;
                !currentFilters.length
                  ? (valueToAdd = `?${prop}=${values.key}`)
                  : (valueToAdd = `&${prop}=${values.key}`);
                currentFilters = currentFilters + valueToAdd;
              });
              break;
            case FILTERS.territories:
              territories.forEach((values) => {
                let valueToAdd;
                !currentFilters.length
                  ? (valueToAdd = `?${prop}=${values.key}`)
                  : (valueToAdd = `&${prop}=${values.key}`);
                currentFilters = currentFilters + valueToAdd;
              });
              break;
          }
        }
      }
    }
    const myParams = {
      queryString: currentFilters,
      challengeData: getChallengeData(allValues['challengeData']),
      currentFilters: allValues,
    };
    onFiltersChanged(myParams);

    function getChallengeData(value) {
      if (value) {
        return `${value}`;
      } else {
        return '';
      }
    }
  };

  const callbackTabOnChange = (key) => {
    setActiveTab(key);
  };

  return (
    <>
      <div className={'sidebar-mc-btn'}>
        <Button type="primary" onClick={showDrawer}>
          <FilterOutlined />
        </Button>
      </div>
      <div>
        <Drawer closable={false} mask={false} onClose={onClose} visible={visible}>
          <Row className="drawer-header">
            <Col span={1}>
              <FilterOutlined className="hand-cursor" onClick={hideDrawer} />
            </Col>
            <Col span={6} offset={4}>
              <p>Filtros </p>
            </Col>
            <Col span={1} offset={12}>
              <ReloadOutlined className="hand-cursor" onClick={resetForm} />
            </Col>
          </Row>
          <Form
            form={form}
            onValuesChange={(changedValues, allValues) => {
              handleFiltersChange(allValues, changedValues);
            }}
          >
            <Collapse
              accordion
              defaultActiveKey={['1']}
              activeKey={activeTab}
              onChange={callbackTabOnChange}
            >
              <Panel header="Estructura comercial" key="1" showArrow={false}>
                <p>Región</p>
                <BSelect
                  disabled={isLoadingTables}
                  mode={'multiple'}
                  name={FILTERS['regions']}
                  options={renderSelectOptions(regions)}
                  label={''}
                  loading={regionsLoaded}
                  selectAll={true}
                  onChange={(data) =>
                    handleChange(data, FILTERS['regions'], renderSelectOptions(regions))
                  }
                  maxTagCount={5}
                />

                <p>Línea de negocio</p>
                <BSelect
                  disabled={isLoadingTables}
                  mode={'multiple'}
                  name={FILTERS['bussines-line']}
                  options={renderSelectOptions(businessLines)}
                  label={''}
                  loading={businessLinesLoaded}
                  selectAll={true}
                  onChange={(data) =>
                    handleChange(data, FILTERS['bussines-line'], renderSelectOptions(businessLines))
                  }
                  maxTagCount={5}
                />

                <p>Subregión</p>
                <BSelect
                  disabled={isLoadingTables}
                  mode={'multiple'}
                  name={FILTERS['subregions']}
                  options={renderSelectOptions(subregions)}
                  label={''}
                  loading={subregionsLoaded}
                  selectAll={true}
                  onChange={(data) =>
                    handleChange(data, FILTERS['subregions'], renderSelectOptions(subregions))
                  }
                  maxTagCount={5}
                />

                <p>Territorio</p>
                <BSelect
                  disabled={isLoadingTables}
                  mode={'multiple'}
                  name={FILTERS['territories']}
                  options={renderSelectOptions(territories)}
                  label={''}
                  loading={territoriesLoaded}
                  selectAll={true}
                  onChange={(data) =>
                    handleChange(data, FILTERS['territories'], renderSelectOptions(territories))
                  }
                  maxTagCount={5}
                />

                <p>Zona</p>
                <BSelect
                  disabled={isLoadingTables}
                  mode={'multiple'}
                  name={FILTERS['zones']}
                  options={renderSelectOptions(zones)}
                  label={''}
                  loading={zonesLoaded}
                  selectAll={true}
                  onChange={(data) =>
                    handleChange(data, FILTERS['zones'], renderSelectOptions(zones))
                  }
                  maxTagCount={5}
                />
              </Panel>
              <Panel header="Desafío de ventas" key="2" showArrow={false}>
                <p>Estatus</p>
                <BSelect
                  disabled={isLoadingTables}
                  mode={'multiple'}
                  name={FILTERS['sales-status']}
                  options={renderSelectOptions(salesStatus)}
                  label={''}
                  loading={salesStatusLoaded}
                  selectAll={true}
                  onChange={(data) =>
                    handleChange(data, FILTERS['sales-status'], renderSelectOptions(salesStatus))
                  }
                  maxTagCount={5}
                />
                <p>Ventas/Volumen</p>
                <BSelect
                  disabled={isLoadingTables}
                  name={FILTERS['sales-volume']}
                  options={renderSelectOptions(salesVolume)}
                  label={''}
                  initialValue={'1'}
                  allowClear={false}
                  onChange={(data) => handleChange(data, FILTERS['sales-volume'])}
                />
              </Panel>
            </Collapse>
          </Form>
        </Drawer>
      </div>
    </>
  );
}

Sidebar.propTypes = {
  onFiltersChanged: PropTypes.func,
  isLoadingTables: PropTypes.bool.isRequired,
};

export default Sidebar;
